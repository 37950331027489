<template>
    <section class=" my-4 py-5 midSep">
        <div class="container text-center py-1">
            <p class="mb-4 w-auto m-auto rounded-4 px-4" style="background-color: rgba(255,255,255,0.2);backdrop-filter: blur(15px);max-width: fit-content;font-size: 1.6rem;">Served  <span class="p-1"><strong>4000+</strong></span> happy vacationers in 5 years.</p>
        </div>
    </section>
</template>
<style lang="css" scoped>
 .midSep {
    background-image: url('@/assets/homeimgs/numbers-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
    min-height:300px;
 }
</style>