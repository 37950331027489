<template>
    <div id="carouselExampleControls" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src="@/assets/slider/river-rafting.jpg" class="d-block w-100" alt="River Rafting">
            </div>
            <div class="carousel-item">
            <img src="@/assets/slider/forest-road-clouds-top.jpg" class="d-block w-100" alt="Forest Roads">
            </div>
            <div class="carousel-item">
            <img src="@/assets/slider/shimla-hill-houses.jpg" class="d-block w-100" alt="Shimla Hills">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>