<template>
    <section>
        <div class="container bg-primary-gradient py-5">
            <div class="row">
                <div class="col-md-8 col-xl-6 text-center mx-auto">
                    <p class="fw-bold text-dark mb-2">We Specialize in regional tours</p>
                    <h3 class="fw-bold">Our Featured Packages</h3>
                </div>
            </div>
            <div class="py-5 p-lg-5">
                <div class="container align-items-center" style="max-width: 900px;">
                    <!-- <home-card 
                        title="CHOPTA VALLEY"
                        image="chopta-valley.jpg"
                        alt="Chopta Valley"
                        category="Adventure"
                        desc="5D/ 4N HARIDWAR - CHOPTAVALLEY - RISHIKESH"
                        waNumber="8879355793"
                        hardEntry="true"
                    ></home-card>
                    <home-card 
                        title="KASHMIR"
                        image="kashmir.jpg"
                        alt="KASHMIR"
                        category="North India"
                        desc="6D/ 5N SRINAGAR - GULMARG - PAHALGAM - SRINAGAR - SONMARG - SRINAGAR"
                        waNumber="8879355793"
                        hardEntry="true"

                    ></home-card>
                    <home-card 
                        title="CHAR DHAM"
                        image="pilgrim-kedarnath.jpg"
                        alt="CHARDHAM"
                        category="Pilgrimage"
                        desc="12D/ 12N HARIDWAR - BARKOT - YAMNOTRI - BARKOT - UTTARKASHI - GANGOTRI - UTTARKASHI - GUPTKASHI - KEDARNATH - GUPTKASHI - GOCHAR - BADRINATH - GOCHAR - RISHIKESH - HARIDWAR"
                        waNumber="8879355793"
                        hardEntry="true"

                    ></home-card>
                    <home-card 
                        title="ANDAMAN"
                        image="andaman.jpg"
                        alt="ANDAMAN"
                        category="South India"
                        desc="5D/ 4N PORT BLAIR - HAVELOCK - RADHAGAR BEACH - HAVELOCK - KALAPATHAR BEACH - PORT BLAIR"
                        waNumber="8879355793"
                        hardEntry="true"

                    ></home-card> -->


                    <button class="btn btn-o-danger" type="button" disabled v-if="loading">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                    </button>
                    <div class="row align-items-start align-content-md-center" v-else>
                                <home-card 
                                    v-for="d,index in destis" :key="index"
                                    :title="d.name"
                                    :image="d.data[0].files[0]"
                                    :alt="d.name"
                                    :category="d.category"
                                    :desc="d.daynight"
                                    waNumber="8879355793"
                                    :index="index"
                                    @desti="info($event)"
                                    :baseURL="baseUrl"
                                    :tourId="d.id"
                                    classes="col-md-6 mb-4"
                                    
                                ></home-card>
                                
                    </div>
                   
                </div>
            </div>
        </div>
    </section>
</template>
<style>
.card-body > div > h6{
    color: rgb(117, 117, 117);
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding: 2px 8px 2px 20px;
    background: rgb(205, 207, 102);
    position: absolute;
    left: 0px;
    bottom: 45%;
}
</style>
<script>
import axios from 'axios';
import homeCard from '@/components/subs/homeCard.vue'
import urlConf from './configs/urlConf';
export default {
    components:{
        homeCard
    },
    data(){
        return {
            featList:[41,45,56,67],
            destis:[],
            loading:false,
        }
    },
    methods:{
        async getDests(){
            this.loading=true;
            let formData = new FormData();
            formData.append('featured',this.featList);
            await axios.post(urlConf.baseUrl+"php/getDestinations.php", formData,{
                  headers: {
                    'Content-Type': 'multipart/form-data'
                    },
              })
            .then(resp=>{
                this.destis = resp.data;
                /* console.log(this.destis);
                console.log("+++++++"); */
                this.destis.forEach((d,ind)=>{
                try {            
                    d.data=JSON.stringify(d.data);
                    //console.log(JSON.parse(JSON.parse(d.data)));
                    d.data = JSON.parse(JSON.parse(d.data));
                    //console.log(d.data);
                    this.loading=false;
                } catch (error) {
                    console.log(error);
                }
                })
            })
            .catch(error=>{
                console.log(error);
                this.loading=false;
            });

        }
    },
    mounted(){
        this.getDests();
    }
    
}
</script>