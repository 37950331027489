<template>
    <div class="container-fluid py-5 botSep">
        <div class="mx-auto" style="max-width: 900px;">
            <div class="row row-cols-1 row-cols-md-2 d-flex justify-content-center">
                <div class="col mb-4">
                    <div class="card backblur bg-primary-light rounded-4">
                        <div class="card-body text-center px-4 py-5 px-md-5">
                            <p class="fs-4 fw-bold card-text defColor mb-2">Fully Managed</p>
                            <h5 class="fw-bold card-title mb-3">From ticket booking to local transport arrangement, we do it for you.</h5>
                        </div>
                    </div>
                </div>
                <div class="col mb-4">
                    <div class="card backblur bg-secondary-light rounded-4">
                        <div class="card-body text-center px-4 py-5 px-md-5">
                            <p class="fs-4 fw-bold card-text defColor mb-2">Adequately Timed</p>
                            <h5 class="fw-bold card-title mb-3">We ensure you vacate the right place in the right season for your needs.</h5>
                        </div>
                    </div>
                </div>
                <div class="col mb-4">
                    <div class="card backblur bg-info-light rounded-4">
                        <div class="card-body text-center px-4 py-5 px-md-5">
                            <p class="fs-4 fw-bold card-text defColor mb-2">Flexible Options</p>
                            <h5 class="fw-bold card-title mb-3">Customized itineraries to meet your comfort and joy needs.</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="css" scoped>
 .botSep {
    background-image: url('@/assets/homeimgs/mid-section-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height:300px;
 }
 .backblur{
    backdrop-filter: blur(10px);
    background-color: #ffffff69;
 }
 .defColor{
    color:#2c3e50;
 }
 
</style>