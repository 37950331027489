<template>
    <div :class="classes">
                        <div class="card homeCard" >
                            <!-- <div class="card-img" style="background-image: url('@/assets/homeimgs/chopta-valley.jpg')">
                            </div> -->
                            <!-- <img v-if="hardEntry" :src="require(`@/assets/homeimgs/${image}`)" :alt="alt" class="mb-2">
                            <img v-else :src="getImg(image)" :alt="alt" class="mb-2"/>
 -->
                            <div v-if="hardEntry"   :alt="alt" class="imgHldr mb-2"></div>
                            <div v-else class="imgHldr mb-2" :style="{backgroundImage:`url(${getImg(image)})`}"></div>
                            <!-- <div v-else style="inlineBgImageStyle"  :alt="alt" class="imgHldr mb-2">as</div> -->
                            
                            <!-- <div class="imgHldr mb-2" :style="{backgroundImage:`url(${getImg(image)})`}"></div> -->

                            <div class="card-body d-flex flex-column justify-content-between" style="padding-bottom: 20px;height: fit-content;">
                                <h6 class="text-dark d-inline ">{{ category }}</h6>
                                <div>
                                    <h4 class="mt-1 fw-bold card-heading">{{ title }}</h4>
                                    <p >{{ desc }}<br /></p>
                                </div>
                                <!-- <a class="btn btn-info text-white d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center mb-1" 
                                   role="button"  @click.prevent="cast(index)">
                                        <font-awesome-icon icon="da-duotone fa-circle-info" style="font-size: larger;" class="mx-2"/>
                                        <b class="my-1">More Details</b>
                                </a> -->
                                <router-link class="btn btn-primary text-white d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center mb-1" :to="{ path: 'tour', query: { id: tourId }}" >
                                    <font-awesome-icon icon="da-duotone fa-circle-info" style="font-size: larger;" class="mx-2"/>
                                    <b class="my-1">More Details</b>
                                </router-link>
                                <a class="btn btn-success d-flex d-xl-flex justify-content-center align-items-center justify-content-xl-center align-items-xl-center" 
                                   role="button" target="_blank" :href="'https://api.whatsapp.com/send/?phone=%2B91'+waNumber+'&amp;text=Hello+There%2C+%0AI+am+interested+in+the+'+title+'+Package&amp;type=phone_number&amp;app_absent=0'">
                                   <font-awesome-icon icon="fa-brands fa-whatsapp" style="font-size: larger;" class="mx-2"/>
                                        Get Quote
                                </a>
                            </div>
                        </div>
    </div>
</template>
<script>

export default {
    
    props:{
        classes:{default:"col-md-3 mb-4", type:String},
        image:String,
        title:{default:"Holiday Destination", type:String},
        alt:{default:"The Bliss Holidays", type:String},
        category:{default:"Holiday Destination", type:String},
        desc:{default:"The Bliss Holidays", type:String},
        waNumber:{default:"8879355793", type:String},
        index:{default:0, type:Number},
        hardEntry:{default:false, type:Boolean},
        baseURL:{default:'/', type: String},
        tourId:String
        },
    computed:{
        whatsApp(){
            return "https://api.whatsapp.com/send/?phone=%2B91"+this.waNumber+"&amp;text=Hello+There%2C+%0AI+am+interested+in+the+"+this.title.replace(" ","+")+"+Package&amp;type=phone_number&amp;app_absent=0"
        },
        imgUrl(){
            return "url("+this.image+")";
        },
        inlineBgImageStyle() {
            console.log(this.image);
            return 'background-image: url("'+this.baseURL+'php/getThumbnail.php?thumb=yes&filename='+this.image+'")"'; 
            
        }
    },
    methods:{
        cast(e){
            this.$emit("desti",e);
        },
        getImg(img){
            return this.baseURL+"php/getThumbnail.php?thumb=yes&filename="+img;
            /* return "https://tester.coredecimalsolutions.com/php/uploads/"+img; */
        },
        getwhatsApp(){
            return "https://api.whatsapp.com/send/?phone=%2B91"+this.waNumber+"&amp;text=Hello+There%2C+%0AI+am+interested+in+the+"+this.title.replace(" ","+")+"+Package&amp;type=phone_number&amp;app_absent=0"
        }
    }
}
</script>
<style>
/* .card > img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 291px;
    width: 361px;
    overflow: hidden;
} */
.card > .imgHldr {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width:auto;
    height: 221px;
    background-repeat: no-repeat;
    background-size: cover;
}
.card-body > div > .card-heading{
    font-family: 'Source Sans Pro', sans-serif;font-weight: 700;color: #d73539;margin-bottom: 8px;margin-top: 22px;
}
/*rgb(255,160,0) */
.card-body > div > p{
    font-family: 'Source Sans Pro', sans-serif;color: #212121;
}
.card-body > a:nth-child(2) {
    margin-top: 20px;background: #128C7E;border-style: none;padding-top: 8px;padding-bottom: 8px;box-shadow: 2px 2px 8px rgba(117,117,117,0.71);
}
.card-body > a > i {
    color: rgb(255,255,255);font-size: 25px;margin-right: 16px;
}
/* .card-img{
    width: 100%;
    height: 230px;
    background-position: center;
    background-size:cover;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    position: relative;
} */
.card-body > h6{
    color: rgb(117, 117, 117);
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding: 2px 8px 2px 20px;
    background: rgb(205, 207, 102);
    background-size: cover;
    margin-left: -16px;
    max-width: fit-content;
    margin-top: -40px; 
}
.homeCard{
    border-radius: 20px !important;
    box-shadow: 2px 2px 16px 8px rgba(0,0,0,0.1);
    border-style: none;
    margin: 16px;
}
</style>