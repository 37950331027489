<template>
    
        <nav id="mainNav" class="navbar navbar-light navbar-expand-md sticky-top navbar-shrink py-3 bg-white">
            <div class="container"><a class="navbar-brand d-flex align-items-center" href="/"><img src="@/assets/logo.png" width="186" height="40" /></a><button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navcol-1"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
                <div id="navcol-1" class="collapse navbar-collapse">
                    <ul class="navbar-nav mx-auto">
                        <li class="nav-item">
                            
                            <router-link class="nav-link fw-bold" :class="isActive('/')? 'active': ''" to="/">Home</router-link>
                        </li>
                        <!-- <li class="nav-item"><a class="nav-link" href="?category=adventure" @click="params()" >Adventure</a></li> -->
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/domestic')? 'active': ''" to="/domestic" >Domestic</router-link></li>
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/international')? 'active': ''" :to="{ path: 'international', query: { category: 'international' }}" >International</router-link></li>
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/pilgrims')? 'active': ''" :to="{ path: 'pilgrims', query: { category: 'pilgrimage' }}" >Pilgrimage</router-link></li>
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/adventure')? 'active': ''" :to="{ path: 'adventure', query: { category: 'adventure' }}" >Adventure</router-link></li>
                        <!-- <li class="nav-item"><a class="nav-link" href="/contact">Contact Us</a></li> -->
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/carrental')? 'active': ''" to="/carrental">Car Rental</router-link></li>
                        <li class="nav-item"><router-link class="nav-link fw-bold" :class="isActive('/contactus')? 'active': ''" to="/contactus">Contact Us</router-link></li>
                    </ul>
                </div>
            </div>
        </nav>
</template>
<script>
export default {
    methods:{
        params(){
            d = this.$route;
            console.log(d);
        },
        isActive(path) {
            return this.$route.path == path;
        }
    }
}
</script>
<style scoped>
navbar-nav .nav-link.active, .navbar-nav .show>.nav-link, nav a.router-link-exact-active {
    color: #d73539 !important;
}
</style>
<!-- ?category=adventure -->