<template >
    <div class="col mb-4" >
        <div class="d-flex flex-column align-items-center align-items-sm-start bg-light rounding">
            <p class="bg-light border rounded border-light p-2">{{ text }}</p>
            <a class="d-flex text-decoration-none bg-gradient bg-secondary w-100 rounding" :href=reviewlink target="_blank">
                <img class="rounded-circle flex-shrink-0 me-3 fit-cover" width="50" height="50" :src="require(`@/assets/team/${img}`)" :alt="alt" >
                <div >
                    <p class="fw-bold text-light mb-0" >{{ name }}</p>
                    <p class="text-dark mb-0">{{ origin }}</p>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        text: {type:String, default:"Testimonial text here"},
        name: {type:String, default:"Testifier Name"},
        origin: {type:String, default:"Origin of testifier"},
        img: {type:String, default:"micon.png"},
        alt: {type:String, default:"A Happy Customer"},
        reviewlink: {type:String, default:"https://g.co/kgs/JDggSg"}
    }
}
</script>
<style scoped>
.rounding{
    border-radius: 25px !important;
}
</style>