<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <nav-bar></nav-bar>
  <router-view/>
  <font-awesome-icon :icon="['fas', 'circle-up']" 
    @click.prevent="toTop()" class="bkTop" :class="topVis? 'd-block':'d-none'" title="Go to top"
  />
  <!-- <button @click.prevent="toTop()" class="bkTop" :class="topVis? 'd-block':'d-none'" title="Go to top">Top</button> -->
  <footerd :copyright="copyr"></footerd>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.hand{
  cursor: pointer;
}

.bkTop {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 9999;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #ffc1075e;
  backdrop-filter: blur(10px);
  color: #2c3e50;
  cursor: pointer;
  padding: 15px;
  border-radius: 25px;
}

.bkTop:hover {
  /* background-color: #2c3e50; */
  background-color: #ffc107;
  color:aliceblue;
}
.backblur-1{
  backdrop-filter: blur(10px);
}
</style>
<style>
  @import '@/assets/css/animate.min.min.css';
  @import '@/assets/css/styles.min.css';
</style>

<script>
import  navBar  from '@/components/navBar.vue';
import footerd from './components/footer.vue';

export default{
  components:{
    navBar,
    footerd
  },
  data(){
    return{
      copyr:"Copyright © 2023 Bliss Holidays",
      topVis:false
    }
  }, 
  mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
  methods: {
      handleScroll: function () {
        if(window.scrollY>200){
          this.topVis=true;
        }
        else this.topVis=false;

      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
  }
}



</script>
