<template>    
    <div class="container-fluid bg-warning justify-content-center pt-2 pb-0" :class="isfull? 'v-100' : '' " id="contact" >
        <div class="row mb-1">
            <div class="col-md-8 col-xl-6 text-center mx-auto">
                <h3 class="fw-bold text-dark mb-2 display-5">Contact Us</h3>
                <h5 class="fw-bold">Call us, Mail us, Follow us...</h5>
            </div>
        </div>
        <img :src=cenImg class="my-2 rounded-circle contact-image"/>
        <div class="row d-flex justify-content-center">
            <div class="col-md-12 d-flex justify-content-center">
                <div class="d-flex flex-wrap flex-md-row justify-content-center align-items-md-start h-100 mb-1">
                    <div class="d-flex align-items-center p-3">
                        <div class="px-2">
                            <!-- <font-awesome-icon icon="fas phone-volume" style="font-size:xx-large" class="mx-2"/> -->
                            <font-awesome-icon :icon="['fas', 'phone-volume']" style="font-size:xx-large"/>
                            <h5 class="fw-bold mb-0">Phone</h5>
                            <p class="fw-bold mb-0"> <a class="defCol" href="callto:+918879355793">+918879355793</a></p>
                            <p class="fw-bold mb-0"> <a class="defCol" href="callto:+918879575793">+918879575793</a></p>
                        </div>
                    </div>
                    <div class="d-flex align-items-center p-3">                        
                        <div class="px-2">
                            <font-awesome-icon :icon="['fa', 'envelope']" style="font-size:xx-large"/>
                            <h5 class="fw-bold mb-0">Email</h5>
                            <p class="fw-bold mb-0"><a class="defCol" href="mailto:deepa@theblissholidays.com">deepa@theblissholidays.com</a></p>
                            <p class="fw-bold mb-0"><a class="defCol" href="mailto:deepatulaskar@hotmail.com">deepatulaskar@hotmail.com</a></p>
                        </div>
                    </div>
                    
                    <div class="d-flex align-items-center p-3">                        
                        <div class="px-2 ">
                            <a class="defCol mx-2" href="https://www.facebook.com/profile.php?id=100071015642921">

                                <font-awesome-icon :icon="['fab', 'facebook']"  style="font-size:xx-large"/>
                            </a>
                            <a class="defCol mx-2" 
                                   role="button" target="_blank" href="https://api.whatsapp.com/send/?phone=%2B918879355793&amp;text=Hello+There%2C+%0AI+am+interested+in+enquire+about+tour+packages&amp;type=phone_number&amp;app_absent=0">
                                   <font-awesome-icon :icon="['fab', 'whatsapp']" style="font-size:xx-large"/>
                            </a>
                            <a class="defCol mx-2" 
                                   role="button" target="_blank" href="https://www.instagram.com/blisssholidays/">
                                   <font-awesome-icon :icon="['fab', 'instagram']" style="font-size:xx-large"/>
                            </a>
                            
                            <h5 class="fw-bold my-4">Connect us on<br>Social Media</h5>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            <!-- <div class="col-md-12">
                <div>
                    <h3>Leave a Message</h3>
                    <form class="p-3 p-xl-4" method="post">
                        <div class="mb-3"><input class="form-control" type="text" id="name-1" name="name" placeholder="Name"></div>
                        <div class="mb-3"><input class="form-control" type="email" id="email-1" name="email" placeholder="Email"></div>
                        <div class="mb-3"><textarea class="form-control" id="message-1" name="message" rows="6" placeholder="Message"></textarea></div>
                        <div><button class="btn btn-primary shadow d-block w-100" type="submit">Send </button></div>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</template>
<style scoped>
    .defCol{
        color:#2c3e50;
    }
</style>
<script>
export default{
    props:{
        cenImg:String,
        isfull:{type:Boolean, default:false}
    }
}
</script>
<style>
.contact-image{
    height: 220px;

}
.v-100{
    height: 80vh;
}
</style>