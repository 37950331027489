<template>
  <div >
    <headerd></headerd>
    <intro-section></intro-section>
    <home-mid-sep></home-mid-sep>
    <home-featured></home-featured>
    <home-bot-sep></home-bot-sep>
    <home-testimonials></home-testimonials>
    <contact-us class="py-5"></contact-us>
    <!-- <img alt="Vue logo" src="../assets/logo.png">-->
  </div>
</template>

<script>
// @ is an alias to /src
import headerd from '@/components/headerSlider.vue';
import introSection from '@/components/introSection.vue';
import homeMidSep from '@/components/homeMidSep.vue';
import homeFeatured from '@/components/homeFeatured.vue';
import homeBotSep from '@/components/homeBotSep.vue';
import homeTestimonials from '@/components/homeTestimonials.vue';
import contactUs from '@/components/contactUs.vue';
export default {
  name: 'HomeView',
  components: {
    headerd, introSection, homeMidSep, homeFeatured, homeBotSep, homeTestimonials, contactUs
  },
  mounted(){
    let d = this.$route;
    console.log(d);
  }
}
</script>
